import request from '../utils/request';
import qs from "qs";

export const getReportNumberList = param => {
	return request.post('/api/blackList/ReportNumberList', qs.stringify(param))
};


export const deleteReportNumber = param => {
	return request.post('/api/blackList/deleteReportNumber', qs.stringify(param))
};

export const getSnailsRecord = param => {
	return request.post('/api//numberPool/snailsRecord', qs.stringify(param))
};

export const findSnailsRecord = param => {
	return request.post('/api/numberPool/findSnailsRecord', qs.stringify(param))
};


export const getData = path => {
	return request.get('/api/invoke/getData?path=' + path, {
		responseType: 'blob'
	})
};
export const downloadReportNumber = param => {
	return request.post('/api/blackList/downloadReportNumber', qs.stringify(param), {
		responseType: 'blob'
	})
};
export const downloadSnails = param => {
	return request.post('/api/numberPool/downloadSnails', qs.stringify(param), {
		responseType: 'blob'
	})
};
export const downloadDownRecord = param => {
	return request.post('/api/numberPool/downloadDownRecord', qs.stringify(param), {
		responseType: 'blob'
	})
};


export const findDownRecord = param => {
	return request.post('/api/numberPool/findDownRecord', qs.stringify(param))
};
export const downRecordDelete = param => {
	return request.post('/api/numberPool/downRecordDelete', qs.stringify(param))
};
export const getupdateDownRecord = param => {
	return request.post('/api/numberPool/updateDownRecord', qs.stringify(param))
};
export const numberPoolGmRecords = param => {
	return request.post('/api/numberPool/getGMRecordCheck', qs.stringify(param))
};
export const getGMAbnormalNumber = param => {
	return request.post('/api/numberPool/getGMAbnormalNumber', qs.stringify(param))
};

export const GMStop = param => {
	return request.post('/api/numberPool/GMStop', qs.stringify(param))
};
//三五绿名单添加
export const getaddSANWUNumber = param => {
	return request.post('/api/blackList/addSANWUNumber', qs.stringify(param))
};

//三五绿名单列表
export const getSANWUNumberList = param => {
	return request.post('/api/blackList/SANWUNumberList', qs.stringify(param))
};

//三五绿名单删除
export const deleteSANWUNumber = param => {
	return request.post('/api/blackList/deleteSANWUNumber', qs.stringify(param))
};
